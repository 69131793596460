<template>
  <v-card
    class="pa-3 mb-3 elevation-0"
    style="margin: 0 auto !important; width: 400px"
  >
    <h2 style="text-align: center; padding-bottom: 15px">
      Change Your Password
    </h2>

    <ValidationObserver ref="userObserver">
      <ValidationProvider
        name="old password"
        rules="required"
        events="['blur']"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="accountUser.oldPassword"
          label="Old Password"
          dense
          outlined
          :error-messages="oldPasswordError || errors"
          :type="showOldPassword ? 'text' : 'password'"
          :append-icon="
            showOldPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="showOldPassword = !showOldPassword"
        ></v-text-field>
      </ValidationProvider>

      <ValidationProvider
        vid="confirmation"
        name="new password"
        rules="required|verify_password"
        events="['blur']"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="accountUser.password"
          label="New Password"
          :error-messages="errors"
          dense
          outlined
          type="password"
          :append-icon="'mdi-eye-off-outline'"
        ></v-text-field>
      </ValidationProvider>

      <ValidationProvider
        name="confirm password"
        rules="required|confirmed:confirmation"
        events="['blur']"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="accountUser.confirmPassword"
          label="Confirm Password"
          dense
          outlined
          :error-messages="errors"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="
            showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </ValidationProvider>

      <v-btn block class="mt-4" color="grey darken-3" dark @click="onSubmit">
        <v-icon style="font-size: 20px" class="mr-3">mdi-key</v-icon>
        Change Password</v-btn
      >
    </ValidationObserver>
  </v-card>
</template>
<script>
import PageMixin from '@/mixins/page.mixin';
import { mapActions } from 'vuex';
import { AuthService } from '@/services/api/auth.service';

const authService = new AuthService();
export default {
  name: 'UserAccount',
  mixins: [PageMixin],
  data: () => ({
    pageLoading: true,
    showOldPassword: false,
    showPassword: false,
    accountUser: {},
    oldPasswordError: ''
  }),

  beforeRouteLeave(to, from, next) {
    if (
      (this.user && !this.user.shouldChangePassword) ||
      to.path == '/auth/login'
    ) {
      next();
    } else {
      next(false);
    }
  },

  async mounted() {
    try {
      this.pageLoading = true;
    } catch (error) {
      this.httpErrorHandler(error);
    } finally {
      this.pageLoading = false;
    }
  },

  methods: {
    ...mapActions('userModule', ['changePassword']),
    ...mapActions('authModule', ['signOut']),

    async onSubmit() {
      this.oldPasswordError = '';
      let isValid = await this.$refs.userObserver.validate();
      if (!isValid) {
        return;
      }

      try {
        console.log(this.user.email, this.accountUser);
        await authService.signIn(this.user.email, this.accountUser.oldPassword);
      } catch (e) {
        console.log(e);
        this.oldPasswordError = 'Incorrect password.';
        return;
      }

      try {
        await authService.changePassword(
          this.user._id,
          this.accountUser.password
        );
        ``;

        this.showSnack({
          color: 'success',
          title: `Password Updated`,
          message: `You password was successfully changed. Please enter your new credentials to continue.`
        });

        await this.signOut(this.user._id);
        this.$router.push('/auth/login');
      } catch (error) {
        this.httpErrorHandler(error);
        throw error;
      }
    }
  }
};
</script>